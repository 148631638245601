import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";

export const isCorporateClient = createAsyncThunk(
  "personalCabinet/getCorporateClientsCreateThunk",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.isCorporateClient();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
