import axiosApi from "shared/api/axiosApi";
import { userLoginData, userRegisterData } from "entities/user/lib/types";
import { checkCurrentLanguage } from "shared/lib/checkCurrentLanguage";

export const userAPI = {
  register: async (userData: userRegisterData) => {
    return await axiosApi.post("/auth/register", userData, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  confirmEmail: async (query: Record<string, any>) => {
    const params = new URLSearchParams(query);
    return await axiosApi.patch(
      `/auth/confirmemail?${params.toString()}`,
      {},
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  sendconfirmemail: async ({ email }: { email: string }) => {
    return await axiosApi.post(
      `/auth/sendconfirmemail`,
      { email },
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  login: async (userData: userLoginData) => {
    return await axiosApi.post("/auth/login", userData, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  forgotPassword: async (userEmail: string) => {
    return await axiosApi.post(
      `/auth/forgetpassword`,
      { email: userEmail },
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  changePassword: async (queryUserData: string) => {
    return await axiosApi.patch(`/auth/resetpassword?${queryUserData}`, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  refreshToken: async (
    accessToken: string | null,
    refreshToken: string | null
  ) => {
    return await axiosApi.post(
      "/auth/refresh",
      { accessToken, refreshToken },
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  googleLogin: async (googleToken: string) => {
    return await axiosApi.post(
      "/auth/logingoogle",
      { googleToken },
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getActiveMarathons: async () => {
    return await axiosApi.get("/marathons/active", {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  getActiveMarathonById: async (marathonData: {
    marathonId: number;
    page: number;
    pageSize: number;
    participantsSearchInfo: string;
  }) => {
    return await axiosApi.get(
      `/applications/marathon-public/${marathonData.marathonId}?page=${marathonData.page
      }&pageSize=${marathonData.pageSize}${marathonData.participantsSearchInfo &&
      `&filter=%28user%21%3Dnull%29%2C%28%28user.fullName%21%3Dnull%2Cuser.fullName%3D%2A${marathonData.participantsSearchInfo}%2Fi%29%7C%28user.fullNameR%21%3Dnull%2Cuser.fullNameR%3D%2A${marathonData.participantsSearchInfo}%2Fi%29%7C%28number%21%3Dnull%2Cnumber%3D${marathonData.participantsSearchInfo}%2Fi%29%29`
      }`
    );
  },
};
