import { Box, Tabs } from "@mui/material";

import styles from "./marathon.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { ICreateMarathon } from "features/createMarathon/types";
import { useTranslation } from "react-i18next";

export const MarathonNav = () => {
  const {
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  const { t } = useTranslation();

  const hasStep1Errors =
    errors.dateEvent || errors.documents || errors.translations;

  const hasStep2Errors =
    errors.startDateAcceptingApplications ||
    errors.endDateAcceptingApplications ||
    errors.participantNumbersPrintDate ||
    errors.insurancePrice;

  return (
    <Box className={styles.nav}>
      <div className={hasStep1Errors ? styles.nav_error : undefined}>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.nav_activeLink : styles.nav_link
          }
          to={"/new/step-1"}
        >
          <div className={styles.nav_line}></div>
          <h5 className={styles.nav_title}>{t("createMarathon.title1")}</h5>
          <p className={styles.nav_text}>
            {t("createMarathon.enterInfo")}
          </p>
        </NavLink>
      </div>
      <div className={hasStep2Errors ? styles.nav_error : undefined}>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.nav_activeLink : undefined
          }
          to={"/new/step-2"}
        >
          <div className={styles.nav_line}></div>
          <h5 className={styles.nav_title}>{t("createMarathon.title2")}</h5>
          <p className={styles.nav_text}>
            {t("createMarathon.datesDistances1")}</p>
        </NavLink>
      </div>
    </Box>
  );
};
