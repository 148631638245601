import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";

export const postSubVoucherCreateThunk = createAsyncThunk(
  'personalCabinet/postSubVoucherCreateThunk',
  async ({ subVoucherData }: any, { rejectWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.postSubVoucher({ subVoucherData })
      return data
    } catch (error: any) {
      if (error.response.data.status === 400) {
        return rejectWithValue(error.response.data.title);
      } else {
        return rejectWithValue(error);
      }
    }
  }
)
