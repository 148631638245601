import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertError } from "shared/api/alertError";
import { typedAdminAPI } from "../api/typedApi";

interface getMarathonDistancesProps {
  marathonId: string | number;
}

export const getMarathonDistances = createAsyncThunk<any, any>(
  "getMarathonDistancePrice",
  async (marathonId, { rejectWithValue }) => {
    try {
      const res = await typedAdminAPI.getMarathonDistances(marathonId);
      return res.data;
    } catch (error: any) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response.data.title);
    }
  }
)
