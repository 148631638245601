import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";
import { toast } from "react-toastify";
import i18n from "i18next";

interface MarathonDistancePriceData {
  marathonId: string | number;
  distanceId: number;
  promocodeId?: string | number;
  includeInsurance?: boolean;
}

export const getMarathonDistancePrice = createAsyncThunk<any, MarathonDistancePriceData>(
  "getMarathonDistancePrice",
  async (data, { rejectWithValue }) => {
    try {
      const res = await personalCabinetAPI.getMarathonDistancePrice(data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        if (error.response.data.title) {
          if (error.response.data.title === "The actual cost of the distance has not been found.") {
            const errorMessage = i18n.t('myMarathon.applicationNotAvailable');
            toast.error(errorMessage);
          } else {
            const errorMessage = i18n.t('myMarathon.defaultError');
            toast.error(errorMessage);
          }
        }
      }

      console.log(error, 'error');
      return rejectWithValue(error.response?.data.title || 'Unknown error');
    }
  }
);
