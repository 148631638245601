import { ADMIN_PANEL_PATH, VOUCHERS_PATH } from "pages/paths";
import {
  CloseIcon,
  DeleteIcon,
  ImportExcelFile,
  MinusIcon,
  MoreIcon,
} from "assets/icons/index";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Input,
  AutoCompleteInput,
  Label,
  ModalTitle,
  defaultCircularProgress,
  deleteVoucherPromocodesByIdButton,
  createVoucherAutoComplete,
  voucherListDownloadExcelButton,
} from "entities/admin/lib/MUIStyles/MUIStyles";
import { useNavigate, useParams } from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_RowSelectionState,
} from "material-react-table";
import React, {
  FC,
  FormEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "shared/lib/hooks/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { addSlotsValidation } from "entities/admin/lib/formValidation/addSlotsValidation";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Pagination from "@mui/material/Pagination";
import { PromoModal } from "./LinkingPromoModal/PromoModal";
import Stack from "@mui/material/Stack";
import { SuccessAsignPromode } from "./LinkingPromoModal/SuccessAsignPromocode";
import Typography from "@mui/material/Typography";
import { UnLinkPromoModal } from "./LinkingPromoModal/UnLinkPromoModal";
import closeIcon from "assets/images/modal/x.svg";
import { deleteNonActivatedPromocodes } from "entities/admin/model/thunk/deleteNonActivatedPromocodes";
import { deleteVoucherPromocodesById } from "entities/admin/model/thunk/deleteVoucherPromocodesById";
import { downloadPromocodesExcelFile } from "shared/lib/downloadPromocodesExcel";
import editIcon from "assets/images/admin/editVoucher.svg";
import styles from "./VoucherListTable.module.scss";
import trashIcon from "assets/images/admin/trash_red.svg";
import { useTranslation } from "react-i18next";
import { getMarathonById } from "entities/admin/model/thunk/getMarathonById";
import { getVoucherPromocodesByVoucherId } from "entities/admin/model/thunk/getVoucherPromocodesByVoucherId";
import { updateVoucher } from "entities/admin/model/thunk/EditVoucherName";
import { getSubVouchersCreateThunk } from "entities/viewer/model/thunks/getSubVouchersCreateThunk";
import { useSelector } from "react-redux";
import { deactivateSubVoucher } from "entities/admin/model/thunk/deactivateSubVoucher";
import { updateSubVoucher } from "entities/admin/model/thunk/updateSubVoucher";
import { getUnssignedCountPerDistance } from "entities/viewer/model/thunks/getUnssignedCountPerDistance";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Remove } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { getMarathonDistances } from "entities/admin/model/thunk/getMarathonDistances";
import { addPromocodesToVouchers } from "entities/admin/model/thunk/addPromocodesToVouchers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { voucherPromocodeCheckedHandler } from "entities/admin/model/slice";
import type { OnChangeFn, RowSelectionState } from "@tanstack/table-core";
import ButtonWithProgress from "shared/ui/ButtonWIthProgress/ButtonWithProgress";
import ClearIcon from "@mui/icons-material/Clear";
import { SyntheticEvent } from "react-draft-wysiwyg";
interface IVoucherListTableProps {
  subVoucherId?: any;
  corporateClientId?: any;
  pagination: any;
}

const VoucherListTable: FC<IVoucherListTableProps> = ({
  subVoucherId,
  corporateClientId,
  pagination,
}) => {
  const params = useParams();
  const id = params?.id;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const subVouchers = useSelector(
    (state: any) => state.personalCabinet.subVouchers
  );
  const unssignedCountPerDistance = useSelector(
    (state: any) => state?.personalCabinet?.unssignedCountPerDistance
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [promocode, setPromocode] = useState({});
  const [unPromocodeOpen, setUnPromocodeOpen] = useState(false);
  const [successPromode, setSuccessPromode] = useState<boolean>(false);
  const [promodeEmail, setPromocodeEmail] = useState<string>("");
  const [responsibleUserIdsData, setResponsibleUserIds] = useState();
  const [currentUser, setCurrentUser] = useState<any>({});

  const subvaucherResponsibleUsers = useSelector((state: any) => state.personalCabinet.subvaucherResponsibleUsers);
  const subvaucherResponsibleUsersOptions = subvaucherResponsibleUsers?.responsibleUsers?.map((data: any) => (
    data
  ));

  const [selectedUsers, setSelectedUsers] = useState<any>(subvaucherResponsibleUsersOptions);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [errorFinal, setErrorFinal] = useState<string>();
  const [finalResponsible, setFinalReponsible] = useState<boolean>(false);

  const isGetUsersLoading = useAppSelector(
    (state) => state.marathon.isGetUsersLoading
  );

  const editVoucherLoading = useAppSelector(
    (state) => state.marathon.editVoucherLoading
  );
  const getVoucherPromocodesByVoucherIdLoading = useAppSelector(
    (state) => state.marathon.getVoucherPromocodesByVoucherIdLoading
  );

  const voucherPromocodes: { id: number; checked: boolean }[] = useAppSelector(
    (state) => state.marathon.voucherPromocodes
  );
  const discount = useAppSelector((state) => state.marathon.discount);
  const marathonId = useAppSelector((state) => state.marathon.marathonId);
  const marathonDistances = useAppSelector(
    (state) => state.marathon.marathonDistances
  );
  const isVoucherActive = useAppSelector(
    (state) => state.marathon.isVoucherActive
  );
  const voucherPromocodesAmount = useAppSelector(
    (state) => state.marathon.voucherPromocodesAmount
  );
  const voucherName = useAppSelector((state) => state.marathon.voucherName);
  const startKitIssueType = useAppSelector(
    (state) => state.marathon.startKitIssueType
  );
  const deleteNonActivatedPromocodesLoading = useAppSelector(
    (state) => state.marathon.deleteNonActivatedPromocodesLoading
  );
  const deleteVoucherPromocodesByIdLoading = useAppSelector(
    (state) => state.marathon.deleteVoucherPromocodesByIdLoading
  );
  const currentUserRole = localStorage.getItem("role") ?? "";

  const corporateClients = useAppSelector(
    (state) => state.marathon.corporateClientsByMarathonId
  );

  const users = useAppSelector((state) => state.marathon.users);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [downloadExcelFileLoading, setDownloadExcelFileLoading] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorPromo, setAncorPromo] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [editVoucherName, setEditVoucherName] = useState("");
  const [canCreateSubVoucher, setCanCreateSubVoucher] = useState(false);
  const [editVoucherStartKitIssueType, setEditVoucherStartKitIssueType] =
    useState<number>(1);
  const [isEditVoucherModalOpen, setIsEditVoucherModalOpen] =
    useState<boolean>(false);
  const [isVoucherDeactiveModalOpen, setIsVoucherDeactivateModalOpen] =
    useState<boolean>(false);
  const [isVoucherActivateModalOpen, setIsVoucherActivateModalOpen] =
    useState<boolean>(false);
  const [
    isVoucherDeleteNonActivatedModalOpen,
    setIsVoucherDeleteNonActivatedModalOpen,
  ] = useState<boolean>(false);

  const [isAddNewSlotesModalOpen, setAddNewSlotesModalOpen] =
    useState<boolean>(false);

  const open = Boolean(anchorEl);
  const openPromo = Boolean(anchorPromo);

  const handleEditVoucherModalClose = () => {
    setIsEditVoucherModalOpen(false);
  }
  const handleVoucherDeactivateModalClose = () =>
    setIsVoucherDeactivateModalOpen(false);
  const handleVoucherActivateModalClose = () =>
    setIsVoucherActivateModalOpen(false);
  const handleVoucherDeleteNonActivatedModalClose = () =>
    setIsVoucherDeleteNonActivatedModalOpen(false);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addSlotsValidation),
    defaultValues: { fields: [{ value: "", slots: "" }] },
  });

  const handleDistanceChange = (
    event: SelectChangeEvent<string>,
    index: number
  ) => {
    const newDistance = event.target.value;
    setValue(`fields.${index}.value`, newDistance);
    setValue(`fields.${index}.slots`, "");
  };

  const handleAddNewSlotesModalClose = () => {
    setAddNewSlotesModalOpen(false);
    reset();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handlePromocodeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAncorPromo(event.currentTarget);
  };

  const handlePromoClose = () => setAncorPromo(null);

  const handleOnPageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => setPage(page);

  const downloadExcelFileHandler = async (voucherId: number) => {
    setDownloadExcelFileLoading(true);
    await downloadPromocodesExcelFile(voucherId);
    setDownloadExcelFileLoading(false);
  };

  useEffect(() => {
    if (marathonId) {
      dispatch(getMarathonDistances(marathonId));
    }
  }, [marathonId]);

  useEffect(() => {
    dispatch(getUnssignedCountPerDistance({ voucherId: id }));
  }, [id]);

  useEffect(() => {
    if (corporateClientId) {
      dispatch(
        getSubVouchersCreateThunk({
          voucherId: subVoucherId,
          pagination: pagination,
        })
      );
    }
  }, [
    corporateClientId,
    dispatch,
    subVoucherId,
    t,
    pagination,
    marathonDistances,
  ]);


  // useEffect(() => {
  //   if (voucherId) {
  //     dispatch(
  //       getSubVouchersCreateThunk({
  //         voucherId,
  //         corporateClientId: null,
  //         pagination,
  //       })
  //     );
  //   }
  // }, [voucherId, pagination]);

  const deletePromocodes = async () => {
    const modifiedCheckedPromocodes = voucherPromocodes
      .filter((voucherPromocode) => voucherPromocode.checked)
      .map((checkedPromocode) => checkedPromocode.id);

    if (params.id) {
      const response = await dispatch(
        deleteVoucherPromocodesById({
          voucherId: +params.id,
          promocodesId: modifiedCheckedPromocodes,
        })
      );

      if (response.meta.requestStatus === "fulfilled") {
        dispatch(
          getVoucherPromocodesByVoucherId({
            page,
            pageSize,
            voucherId: +params.id,
          })
        );
      }
    }
  };

  const editVoucherSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!params.id) return;


    const responsibleUserIds = selectedUsers?.map((usersId: any) => Number(usersId?.id));

    if (subVouchers) {
      const subvoucherData = {
        subVoucherId: +params.id,
        name: voucherName,
        isActive: isVoucherActive,
        startKitIssueType: editVoucherStartKitIssueType,
        responsibleUserIds: responsibleUserIds ? responsibleUserIds : [],
      };

      const response = await dispatch(
        updateSubVoucher(subvoucherData)
      ).unwrap();

      dispatch(
        getVoucherPromocodesByVoucherId({
          page,
          pageSize,
          voucherId: +params?.id,
        })
      );
      handleEditVoucherModalClose();
      setSelectedUsers([]);
      setActiveModal(null);
      setInputValue("");
    } else {
      if (params.id) {
        const voucherData = {
          id: +params.id,
          name: editVoucherName,
          startKitIssueType: editVoucherStartKitIssueType,
          isActive: isVoucherActive,
          canCreateSubVoucher: canCreateSubVoucher,
        };

        const response = await dispatch(updateVoucher(voucherData));

        if (response.meta.requestStatus === "fulfilled") {
          dispatch(
            getVoucherPromocodesByVoucherId({
              page,
              pageSize,
              voucherId: +params.id,
            })
          );
          handleEditVoucherModalClose();
        }
      }
    }
  };

  const handleIsActivationVoucher = async (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
    isActive: boolean
  ) => {
    e.preventDefault();

    if (!params.id) return;

    try {
      if (subVouchers) {
        const subvoucherData = {
          subVoucherId: +params.id,
          name: voucherName,
          isActive,
        };

        const response = await dispatch(
          deactivateSubVoucher(subvoucherData)
        ).unwrap();

        dispatch(
          getVoucherPromocodesByVoucherId({
            page,
            pageSize,
            voucherId: +params.id,
          })
        );
        handleVoucherDeactivateModalClose();
        handleVoucherActivateModalClose();
      } else {
        const voucherData = {
          id: +params.id,
          name: voucherName || "Default Name",
          startKitIssueType: editVoucherStartKitIssueType,
          isActive,
        };

        const response = await dispatch(updateVoucher(voucherData)).unwrap();

        dispatch(
          getVoucherPromocodesByVoucherId({
            page,
            pageSize,
            voucherId: +params.id,
          })
        );

        handleVoucherDeactivateModalClose();
        handleVoucherActivateModalClose();
      }
    } catch (error) {
      console.error("Ошибка при обновлении ваучера:", error);
    }
  };

  const handleDeleteNonActivatedVoucher = async (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (params.id) {
      const response = await dispatch(deleteNonActivatedPromocodes(+params.id));
      if (response.meta.requestStatus === "fulfilled") {
        navigate(-1);
        handleVoucherDeleteNonActivatedModalClose();
      }
    }
  };

  const getPromocodeColor = (count: any) => {
    if (count <= 3) return "#FA0E00";
    if (count <= 15) return "#BA8E1E";
    if (count >= 20) return "#237657";

    return "#CBD5E1";
  };

  const userRole = useAppSelector((state) => state?.users.userRole);
  const navigate = useNavigate();
  const breadcrumbs = [
    <Box
      onClick={() =>
        navigate(
          // @ts-ignore
          userRole === "Owner"
            ? "/" + ADMIN_PANEL_PATH + "/" + VOUCHERS_PATH
            : -1
        )
      }
      className={styles.breadcrumbsLink}
      key={"0"}
    >
      {t("corporateClient.vouchers")}
    </Box>,
    <Typography
      key="1"
      sx={{
        fontFamily: "Raleway, serif",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#2C2C35",
      }}
    >
      {voucherName}
    </Typography>,
  ];


  const columns = useMemo(
    () => [
      {
        accessorKey: "code",
        header: t("corporateClient.promo_code"),
        muiTableBodyCellProps: {
          sx: {
            width: 250,
            minWidth: 250,
            maxWidth: 250,
            whiteSpace: "normal",
            wordBreak: "break-word",
          },
        },
        Cell: (value: any) => value.renderedCellValue,
      },
      {
        accessorKey: "distance",
        header: t("corporateClient.distance"),
        size: 100,
        Cell: (value: any) => (
          <>
            {value.renderedCellValue}
            {/* <img src={arrow} alt="sort" className={styles.arrows} /> */}
          </>
        ),
      },
      {
        accessorKey: "isActivated",
        header: t("corporateClient.status"),
        enableSorting: false,
        size: 130,
        Cell: (value: any) => (
          <Typography
            // onClick={() => LinkToTable(voucher)}
            sx={{
              color:
                value.row.original?.user?.name &&
                  value.row.original?.isActivated
                  ? "#237657"
                  : value.row.original?.user?.name &&
                    !value.row.original?.isActivated
                    ? "#BA8E1E"
                    : !value.row.original?.user?.name &&
                      !value.row.original?.isActivated
                      ? "#FA0E00"
                      : "",
              fontVariantNumeric: "lining-nums proportional-nums",
              fontFamily: "Raleway",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            {value.row.original?.user?.name && value.row.original?.isActivated
              ? t("corporateClient.used")
              : value.row.original?.user?.name &&
                !value.row.original?.isActivated
                ? t("corporateClient.booked")
                : !value.row.original?.user?.name &&
                  !value.row.original?.isActivated
                  ? t("corporateClient.not_used")
                  : ""}
          </Typography>
        ),
      },
      {
        accessorKey: "user",
        header: t("corporateClient.user"),
        size: 130,
        Cell: (value: any) => {
          return value.renderedCellValue?.fullNameR || null;
        },
      },
      {
        accessorKey: "discount",
        header: t("corporateClient.discount"),
        size: 80,
        Cell: (value: any) => {
          return `${discount} %`;
        },
      },
      {
        size: 175,
        accessorKey: "number",
        header: t("corporateClient.phone_number"),
        Cell: (value: any) => {
          const data = value.row.original.user;
          return data?.phoneNumber || null;
        },
      },
      {
        accessorKey: "edit",
        header: t("corporateClient.book_promo"),
        size: 50,
        enableSorting: false,
        Cell: (value: any) => {
          return (
            <div>
              {(
                <>
                  {value.row.original?.isActivated ? (
                    ""
                  ) : value.row.original?.user?.name ? (
                    <IconButton
                      onClick={() => {
                        setUnPromocodeOpen(true);
                        setPromocode(value.row.original);
                      }}
                      sx={{
                        "&:hover": {
                          background: "#0090D1",
                        },
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <MinusIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpenModal(true);
                        setPromocode(value.row.original);
                      }}
                      sx={{
                        "&:hover": {
                          background: "#0090D1",
                        },
                      }}
                    >
                      <svg
                        className="plusIcon"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 8H12"
                          stroke="#0090D1"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#0090D1"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </IconButton>
                    // )
                    // ) : (
                    //   ""
                  )}
                </>
              )}
            </div>
          );
        },
      },
    ],
    [currentUserRole, discount, t]
  );

  useEffect(() => {
    if (params.id) {
      dispatch(
        getVoucherPromocodesByVoucherId({
          page,
          pageSize,
          voucherId: +params.id,
        })
      );
    }
  }, [dispatch, params.id, page, pageSize, t]);

  useEffect(() => {
    if (marathonId) {
      dispatch(getMarathonById(marathonId));
    }
  }, [dispatch, marathonId, t]);

  const handleUpdateState = () => {
    if (params.id) {
      dispatch(
        getVoucherPromocodesByVoucherId({
          page,
          pageSize,
          voucherId: +params.id,
        })
      );
    }
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<{
    isOpen: boolean;
  }>({ isOpen: false });

  const handleRowSelectionChange: OnChangeFn<RowSelectionState> = (
    updaterOrValue
  ) => {
    setRowSelection((prev) => {
      const updatedSelection =
        typeof updaterOrValue === "function"
          ? updaterOrValue(prev)
          : updaterOrValue;

      Object.keys(prev).forEach((index) => {
        if (!updatedSelection[index] && prev[index]) {
          const promocodeId = voucherPromocodes[Number(index)]?.id;
          if (promocodeId) {
            dispatch(
              voucherPromocodeCheckedHandler({
                id: promocodeId,
                checked: false,
              })
            );
          }
        }
      });

      Object.keys(updatedSelection).forEach((index) => {
        if (updatedSelection[index] && !prev[index]) {
          const promocodeId = voucherPromocodes[Number(index)]?.id;
          if (promocodeId) {
            dispatch(
              voucherPromocodeCheckedHandler({
                id: promocodeId,
                checked: true,
              })
            );
          }
        }
      });

      return updatedSelection;
    });
  };

  const table = useMaterialReactTable({
    //@ts-ignore
    columns,
    data: voucherPromocodes || [],
    enableTopToolbar: false,
    enableColumnActions: false,
    manualPagination: true,
    initialState: { density: "comfortable" },
    enablePagination: false,
    enableRowSelection: true,
    onRowSelectionChange: handleRowSelectionChange,
    state: { rowSelection },

    muiTableHeadRowProps: () => ({
      style: {
        background: "#ECF2F7",
        borderRadius: "20px",
        border: "none",
        boxShadow: "none",
      },
    }),
    muiTablePaperProps: {
      style: {
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        boxShadow: "none ",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        height: "10px",
        fontSize: "12px",
        lineHeight: "20px",
        fontWeight: 600,
        fontFamily: "Raleway",
        border: "none",
        "& .MuiTableSortLabel-icon": {
          color: "black !important",
          opacity: 1,
        },
      },
    },
    muiBottomToolbarProps: {
      style: {
        border: "none",
        boxShadow: "none ",
      },
    },
    muiTableBodyCellProps: {
      style: {
        fontSize: "12px",
        fontVariantNumeric: "lining-nums proportional-nums",
        textOverflow: "ellipsis",
        fontFeatureSettings: '"liga" off',
        lineHeight: "20px",
        fontWeight: 500,
        fontFamily: "Raleway",
        color: "#3E444A",
        border: "none",
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        // navigate(
        //   `/${PERSONAL_CABINET_PATH}/${VOUCHERS_PROMOCODES_PATH}/${subVoucher.id}`
        // );
      },
      sx: {
        //stripe the rows, make odd rows a darker color
        cursor: "pointer",
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#F5FAFF",
        },
      },
    }),
    muiPaginationProps: {},
    localization: {
      sortByColumnAsc: t("corporateClient.sort_by"),
      sortedByColumnAsc: "asdasda",
    },
  });

  useEffect(() => {
    setRowSelection(() => {
      const newRowSelection: MRT_RowSelectionState = {};

      voucherPromocodes.forEach((promo, index) => {
        if (promo.checked) {
          newRowSelection[index] = true;
        }
      });

      return newRowSelection;
    });
  }, [voucherPromocodes]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const watchFields = watch("fields");

  const onSubmit = async (data: any) => {
    const promocodeData = data.fields.map((field: any) => ({
      distanceId: field.value,
      quantity: field.slots,
    }));

    const voucherId = params?.id && +params?.id;

    const requestBody = {
      voucherId,
      promocodes: promocodeData,
    };

    try {
      await dispatch(addPromocodesToVouchers(requestBody));
      setAddNewSlotesModalOpen(false);
      reset();

      dispatch(getUnssignedCountPerDistance({ voucherId: id }));

      if (voucherId) {
        dispatch(
          getVoucherPromocodesByVoucherId({
            page,
            pageSize,
            voucherId,
          })
        );
      }
    } catch (error) {
      console.error(
        "Ошибка при добавлении промокодов или получении данных:",
        error
      );
    }
  };

  const handleRemoveUser = (userId: number) => {
    setSelectedUsers((prevSelectedUsers: any) =>
      prevSelectedUsers.filter((user: any) => user?.id !== userId)
    );
  };

  const availableOptions = users?.query
    ?.filter(
      (user: any) =>
        !selectedUsers?.find(
          (selectedUser: any) => selectedUser?.email === user?.email
        )
    )
    .filter(
      (user: any) =>
        user?.email?.toLowerCase().includes(inputValue.toLowerCase()) ||
        user?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
        user?.surname?.toLowerCase().includes(inputValue.toLowerCase())
    );

  return (
    <div className={styles.voucherListTableContainer}>
      <div className={styles.voucherListTableTop}>
        <Stack spacing={2} sx={{ mb: "20px" }}>
          <Breadcrumbs
            sx={{ color: "#0090D1" }}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <div className={styles.voucherNameContainer}>
          <h2 className={styles.voucherName}>{voucherName}</h2>

          <Button type="button" onClick={handleMenuClick}>
            <MoreIcon />
          </Button>
        </div>
      </div>

      <Box
        sx={{ display: "grid", gridTemplateColumns: "25% 75%", gap: "16px" }}
      >
        <Box className="container-personalCabinet" sx={{ maxWidth: "350px" }}>
          <h2
            className="title-personalCabinet"
            style={{ marginBottom: "28px" }}
          >
            {t("corporateClient.distances")}
          </h2>
          <Box
            sx={{
              maxWidth: 400,
              backgroundColor: "#F1F5F9",
              padding: "8px",
              borderRadius: 2,
              maxHeight: "50vh",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px", display: "none" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#CBD5E1",
                borderRadius: "6px",
              },
              "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
            }}
          >
            {unssignedCountPerDistance?.length > 0 ? (
              unssignedCountPerDistance.map((promocode: any) => (
                <Box
                  key={promocode?.key}
                  sx={{
                    mt: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    p: "12px 16px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 2,
                    width: "100%",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      minWidth: 0,
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t("vouchersPage.freeSlotsAtDistance")}{" "}
                    {promocode?.distanceName}
                  </Typography>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderColor: "#CBD5E1",
                      borderStyle: "dashed",
                      alignSelf: "stretch",
                    }}
                  />

                  <Typography
                    sx={{
                      minWidth: 40,
                      textAlign: "center",
                      color: getPromocodeColor(promocode?.promocodeCount),
                    }}
                  >
                    {promocode?.promocodeCount}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography sx={{ textAlign: "center", color: "#CBD5E1" }}>
                {t("vouchersPage.noAvailableDistances")}
              </Typography>
            )}
          </Box>
        </Box>
        <div className={styles.voucherList}>
          <div className={styles.voucherListTop}>
            <div className={styles.voucherListTitleContainer}>
              <h3 className={styles.voucherListTitle}>
                {t("corporateClient.list_of_promo_codes")}
              </h3>
              <span style={{ fontSize: 20 }}>{voucherPromocodesAmount}</span>
            </div>

            {/* Table Menu Desktop */}
            {isMatch ? (
              <Button type="button" onClick={handlePromocodeClick}>
                <MoreIcon />
              </Button>
            ) : (
              <div className={styles.voucherListButtonsContainer}>
                {voucherPromocodes &&
                  voucherPromocodes.some(
                    (voucherPromocode) => voucherPromocode.checked
                  ) &&
                  (currentUserRole === "Admin" ||
                    currentUserRole === "Owner") && (
                    <Button
                      onClick={() => {
                        // deletePromocodes();
                        setIsDeleteModalOpen({ isOpen: true });
                      }}
                      disabled={deleteVoucherPromocodesByIdLoading}
                      sx={{
                        ...deleteVoucherPromocodesByIdButton,
                        background: deleteVoucherPromocodesByIdLoading
                          ? "rgba(25, 118, 210, 0.04)"
                          : "transparent",
                      }}
                      startIcon={<DeleteIcon />}
                      type="button"
                    >
                      Удалить промокоды ({Object.keys(rowSelection)?.length})
                      {deleteVoucherPromocodesByIdLoading && (
                        <CircularProgress
                          size={20}
                          sx={{ marginLeft: "10px" }}
                        />
                      )}
                    </Button>
                  )}
                <Button
                  type="button"
                  variant="text"
                  disabled={downloadExcelFileLoading}
                  onClick={() => {
                    if (params.id) {
                      downloadExcelFileHandler(+params.id);
                    }
                  }}
                  startIcon={<ImportExcelFile />}
                  sx={{
                    ...voucherListDownloadExcelButton,
                    background: downloadExcelFileLoading
                      ? "rgba(25, 118, 210, 0.04)"
                      : "transparent",
                  }}
                >
                  {t("corporateClient.download")}
                  {downloadExcelFileLoading && (
                    <CircularProgress size={20} sx={{ marginLeft: "10px" }} />
                  )}
                </Button>
              </div>
            )}
          </div>
          {getVoucherPromocodesByVoucherIdLoading ? (
            <Box
              sx={{ display: "flex", justifyContent: "center", margin: "50px" }}
            >
              <CircularProgress />
            </Box>
          ) : voucherPromocodes?.length === 0 ? (
            <Box className={styles.noRecords}>
              <p>Нет записей</p>
            </Box>
          ) : (
            <Box sx={{ width: "100%" }}>
              <MaterialReactTable table={table || []} />
            </Box>
          )}
        </div>
      </Box>

      {/* Voucher Menu */}
      <div className={styles.voucherPopUpFirst}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          disableScrollLock={true}
          sx={{
            padding: "0px",
            "& .MuiPaper-root": {
              borderRadius: "16px",
              padding: "0px",
            },
            ".MuiModal-root": {
              padding: "0px",
              margin: "0px",
            },
          }}
          style={{ borderRadius: "10px" }}
          onClose={handleClose}
        >
          {(userRole === "Owner" || userRole === "Admin") && (
            <MenuItem
              className={styles.voucherMenuItem}
              sx={{
                display: "flex",
                padding: "10px",
                fontFamily: "Raleway",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#3E444A",
                borderRadius: "8px",
                "&:hover": { background: "#F0F8FF", color: "#0090D1" },
              }}
              onClick={() => {
                handleClose();
                setAddNewSlotesModalOpen(true);
              }}
            >
              <ListItemIcon sx={{ minWidth: "24px" }}>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <span>Добавить слоты</span>
            </MenuItem>
          )}
          <MenuItem
            className={styles.voucherMenuItem}
            sx={{
              display: "flex",
              padding: "10px",
              fontFamily: "Raleway",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#3E444A",
              borderRadius: "8px",
              "&:hover": { background: "#F0F8FF", color: "#0090D1" },
            }}
            onClick={() => {
              handleClose();
              setEditVoucherName(voucherName);
              setEditVoucherStartKitIssueType(startKitIssueType);
              setIsEditVoucherModalOpen(true);
            }}
          >
            <ListItemIcon sx={{ minWidth: "24px" }}>
              <img src={editIcon} alt="Редактировать" />
            </ListItemIcon>
            <span style={{ marginRight: "auto" }}>Редактировать</span>
          </MenuItem>
          {isVoucherActive ? (
            <MenuItem
              className={styles.voucherMenuItem}
              onClick={() => {
                handleClose();
                setIsVoucherDeactivateModalOpen(true);
              }}
              sx={{
                display: "flex",
                borderRadius: "8px",
                padding: "10px",
                fontFamily: "Raleway",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#3E444A",
                "&:hover": {
                  background: "#F0F8FF",
                  color: "#0090D1",
                },
              }}
            >
              <ListItemIcon>
                <img src={closeIcon} alt="Закрыть" />
              </ListItemIcon>
              <span style={{ marginRight: "auto" }}>Деактивировать</span>
            </MenuItem>
          ) : (
            <MenuItem
              className={styles.voucherMenuItem}
              onClick={() => {
                handleClose();
                setIsVoucherActivateModalOpen(true);
              }}
              sx={{
                display: "flex",
                borderRadius: "8px",
                padding: "10px",
                fontFamily: "Raleway",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#3E444A",
                "&:hover": {
                  background: "#F0F8FF",
                  color: "#0090D1",
                },
              }}
            >
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <span style={{ marginRight: "auto" }}>Активировать</span>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose();
              setIsVoucherDeleteNonActivatedModalOpen(true);
            }}
            sx={{
              borderTop: "1px solid #ECF2F7",
              display: "flex",
              padding: "10px",
              fontFamily: "Raleway",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              borderRadius: "7px",
              color: "#FF6960",
            }}
          >
            <ListItemIcon>
              <img src={trashIcon} alt="Удалить" />
            </ListItemIcon>
            <span style={{ marginRight: "auto" }}>Удалить остатки</span>
          </MenuItem>
        </Menu>
      </div>

      {/* Table Menu Mobile */}
      <div>
        <Menu
          anchorEl={anchorPromo}
          open={openPromo}
          disableScrollLock={true}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "16px",
              padding: "8px",
            },
          }}
          style={{
            borderRadius: "10px",
          }}
          onClose={handlePromoClose}
        >
          {voucherPromocodes.filter(
            (voucherPromocode) => voucherPromocode.checked
          )?.length === 0 ? null : (
            <MenuItem
              className={styles.voucherMenuItem}
              sx={{
                display: "flex",
                padding: "10px",
                fontFamily: "Raleway",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#3E444A",
                borderRadius: "8px",
                // "&:hover": { color: "#0090D1" },
              }}
            >
              <Button
                onClick={deletePromocodes}
                disabled={deleteVoucherPromocodesByIdLoading}
                sx={{
                  ...deleteVoucherPromocodesByIdButton,
                  background: deleteVoucherPromocodesByIdLoading
                    ? "rgba(25, 118, 210, 0.04)"
                    : "transparent",
                }}
                startIcon={<DeleteIcon />}
                type="button"
              >
                Удалить промокоды (
                {
                  voucherPromocodes.filter(
                    (voucherPromocode) => voucherPromocode.checked
                  )?.length
                }
                )
                {deleteVoucherPromocodesByIdLoading && (
                  <CircularProgress size={20} sx={{ marginLeft: "10px" }} />
                )}
              </Button>
            </MenuItem>
          )}
          <MenuItem
            className={styles.voucherMenuItem}
            sx={{
              display: "flex",
              padding: "10px",
              fontFamily: "Raleway",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#3E444A",
              borderRadius: "8px",
              "&:hover": { background: "#F0F8FF", color: "#0090D1" },
            }}
          >
            <Button
              type="button"
              variant="text"
              disabled={downloadExcelFileLoading}
              onClick={() => {
                if (params.id) {
                  downloadExcelFileHandler(+params.id);
                }
              }}
              startIcon={<ImportExcelFile />}
              sx={{
                ...voucherListDownloadExcelButton,
                background: downloadExcelFileLoading
                  ? "rgba(25, 118, 210, 0.04)"
                  : "transparent",
              }}
            >
              Скачать
              {downloadExcelFileLoading && (
                <CircularProgress size={20} sx={{ marginLeft: "10px" }} />
              )}
            </Button>
          </MenuItem>
        </Menu>
      </div>

      {/* {ADD SLOTES} */}
      <Dialog
        open={isAddNewSlotesModalOpen}
        onClose={handleAddNewSlotesModalClose}
        disableScrollLock={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth="md"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle sx={{ px: 0 }}>Добавить слоты</DialogTitle>
          <img
            onClick={handleAddNewSlotesModalClose}
            src={closeIcon}
            className={styles.modalCloseIcon}
            alt="Закрыть"
          />
        </Box>
        <DialogContent sx={{ px: 0 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => {
              const selectedDistance = watchFields?.[index].value;
              const selectedMarathon = marathonDistances?.find(
                (marathon) => marathon?.id === selectedDistance
              );
              const selectedDistances =
                watchFields?.map((item) => item.value) || [];

              const handleDistanceChange = (event: any) => {
                const newDistance = event.target.value;
                setValue(`fields.${index}.value`, newDistance);
                setValue(`fields.${index}.slots`, "");
              };

              return (
                <Box>
                  <Stack
                    key={field.id}
                    direction="row"
                    spacing={2}
                    display="flex"
                    alignItems="center"
                    mb="15px"
                  >
                    <Box sx={{ width: "65%", position: "relative" }}>
                      <InputLabel htmlFor={`distance${index}`} sx={Label}>
                        Дистанция
                      </InputLabel>
                      <Controller
                        name={`fields.${index}.value`}
                        control={control}
                        rules={{ required: "Выберите дистанцию" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            displayEmpty
                            defaultValue=""
                            value={field.value || ""}
                            onChange={(e) => {
                              field.onChange(e);
                              handleDistanceChange(e);
                              trigger(`fields.${index}.value`);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "13px",
                                border: errors.fields?.[index]?.value
                                  ? "2px solid #b63d3c"
                                  : "1px solid #C0D4E3",
                              },
                              backgroundColor: "white",
                              "& .MuiInputBase-input": {
                                padding: "10px",
                              },
                              cursor: "pointer",
                            }}
                          >
                            <MenuItem value="">
                              <Typography
                                component="span"
                                className={styles.selectPlaceholder}
                              >
                                Выберите дистанцию
                              </Typography>
                            </MenuItem>
                            {marathonDistances?.map((marathonDistance) => (
                              <MenuItem
                                key={marathonDistance?.id}
                                value={marathonDistance?.id}
                                disabled={selectedDistances?.includes(
                                  marathonDistance?.id
                                )}
                              >
                                {marathonDistance?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.fields?.[index]?.value && (
                        <Typography
                          color="error"
                          variant="caption"
                          sx={{
                            position: "absolute",
                            bottom: "-20px",
                            left: 0,
                          }}
                        >
                          {errors.fields?.[index]?.value?.message || " "}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ width: "35%", position: "relative" }}>
                      <InputLabel sx={Label}>Слоты</InputLabel>

                      <Controller
                        name={`fields.${index}.slots`}
                        control={control}
                        rules={{ required: "Укажите количество слотов" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            placeholder="Введите кол-во слотов"
                            disabled={
                              !selectedMarathon ||
                              selectedMarathon?.remainingPlaces === 0
                            }
                            inputProps={{
                              min: 1,
                              max: selectedMarathon?.remainingPlaces || 0,
                            }}
                            onChange={(e) => {
                              const value = Math.min(
                                Number(e.target.value),
                                selectedMarathon?.remainingPlaces || 0
                              );
                              setValue(
                                `fields.${index}.slots`,
                                value.toString()
                              );
                              trigger(`fields.${index}.slots`);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "13px",
                                border: errors.fields?.[index]?.slots
                                  ? "2px solid #b63d3c"
                                  : "1px solid #C0D4E3",
                              },
                              backgroundColor: "white",
                              "& .MuiInputBase-input": {
                                padding: "10px",
                                textAlign: field.value ? "center" : "left",
                              },
                              cursor: "pointer",
                            }}
                          />
                        )}
                      />
                      {errors.fields?.[index]?.slots && (
                        <Typography
                          color="error"
                          variant="caption"
                          sx={{
                            position: "absolute",
                            bottom: "-20px",
                            left: 0,
                          }}
                        >
                          {errors.fields?.[index]?.slots?.message || " "}
                        </Typography>
                      )}
                    </Box>

                    <IconButton
                      onClick={() => remove(index)}
                      disabled={fields?.length === 1}
                    >
                      <Remove sx={{ marginTop: "17px" }} />
                    </IconButton>
                  </Stack>
                  <Box
                    sx={{
                      paddingBottom: "15px",
                      textAlign: "end",
                      paddingRight: "5%",
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      mt: "-5px",
                    }}
                  >
                    {selectedMarathon && (
                      <Typography variant="caption" color="textSecondary">
                        Максимальное количество мест:{" "}
                        {selectedMarathon?.remainingPlaces}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
            <IconButton
              onClick={() => append({ value: "", slots: "" })}
              className={styles.addButton}
            >
              <AddOutlinedIcon />
              <Typography>Добавить другую дистанцию</Typography>
            </IconButton>
            <DialogActions sx={{ px: 0 }}>
              <Button
                type="button"
                variant="text"
                onClick={handleAddNewSlotesModalClose}
                sx={{
                  textTransform: "none",
                  color: "#0090D1",
                }}
              >
                Отмена
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#0090D1",
                  borderRadius: "12px",
                  padding: "8px 24px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#0090D1",
                  },
                }}
              >
                Сохранить
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/*   EDIT VOUCHER */}
      <Dialog
        open={isEditVoucherModalOpen}
        onClose={handleEditVoucherModalClose}
        disableScrollLock={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle sx={{ px: 0 }}>
            {" "}
            Редактировать {subVouchers ? "субваучер" : "ваучер"}
          </DialogTitle>
          <img
            onClick={handleEditVoucherModalClose}
            src={closeIcon}
            className={styles.modalCloseIcon}
            alt="Закрыть"
          />
        </Box>
        <DialogContent sx={{ px: 0 }}>
          <form onSubmit={editVoucherSubmit} id="editVoucherForm">
            <InputLabel htmlFor="editVoucherNameInput" sx={Label}>
              Название
            </InputLabel>
            <TextField
              type="text"
              id="editVoucherNameInput"
              value={editVoucherName}
              autoFocus
              fullWidth
              onChange={(e) => setEditVoucherName(e.target.value)}
              sx={Input}
              placeholder="Название Ваучера"
            />

            <Box sx={{ paddingTop: '16px' }}>
              <InputLabel
                htmlFor="addResponsibleUser"
                sx={Label}
              >
                {t("vouchersPage.corporateResponsible")}
              </InputLabel>
              <Autocomplete
                id="addResponsibleUser"
                disablePortal={false}
                size="small"
                sx={{
                  ...createVoucherAutoComplete,
                  width: "100%",
                  marginBottom: "16px",
                }}
                options={availableOptions}
                getOptionLabel={(option: any) => option.email || ""}
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                onChange={(event, option) => {
                  setCurrentUser(option);

                  if (option) {
                    setSelectedUsers((prevSelectedUsers: any) => {
                      if (
                        !prevSelectedUsers?.find(
                          (user: any) => user?.id === option?.id
                        )
                      ) {
                        return [...prevSelectedUsers, option];
                      }
                      return prevSelectedUsers;
                    });
                  }
                  setErrorFinal("");
                  setInputValue("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // helperText={errorFinal}
                    // required={requiredUser}
                    placeholder={t("vouchersPage.selectUser")}
                    // sx={createVoucherDistanceName}
                    error={!!(errorFinal && selectedUsers?.length === 0)}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className={styles.optionName}>
                        {option.email} | {option.name} {option.surname}
                      </span>
                      <span
                        style={{
                          fontSize: 12,
                          color: "#68717B",
                        }}
                      ></span>
                    </li>
                  );
                }}
                inputValue={inputValue}
                loading={isGetUsersLoading}
                loadingText={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress size={30} />
                  </div>
                }
                onInputChange={(event: SyntheticEvent, value) => {
                  setInputValue(value);
                }}
              />
              <div className={styles.selectedEmails}>

                {selectedUsers?.length > 0 ? (
                  <Grid style={{ width: "98%", padding: "10px" }}>
                    {selectedUsers?.map((user: any) => (
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          paddingTop: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid key={user.id}>{user.email}</Grid>
                        <Button
                          onClick={() => handleRemoveUser(user.id)}
                          className={styles.removeButton}
                        >
                          <ClearIcon />
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
              </div>
            </Box>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="startKitIssueType" sx={Label}>
                Тип выдачи старт-пакетов
              </FormLabel>
              <RadioGroup
                value={editVoucherStartKitIssueType}
                onChange={(e) =>
                  setEditVoucherStartKitIssueType(+e.target.value)
                }
                sx={{ height: "40px" }}
              >
                <FormControlLabel
                  id="startKitIssueType"
                  value={1}
                  control={<Radio />}
                  className={styles.createVoucherType}
                  label="Корпоративный"
                />
                <FormControlLabel
                  id="startKitIssueType"
                  value={0}
                  control={<Radio />}
                  className={styles.createVoucherType}
                  label="Индивидуальный"
                />
              </RadioGroup>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions sx={{ px: 0 }}>
          <Button
            type="button"
            variant="text"
            onClick={handleEditVoucherModalClose}
            sx={{
              textTransform: "none",
              color: "#0090D1",
            }}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            form="editVoucherForm"
            variant="contained"
            disabled={editVoucherLoading}
            sx={{
              backgroundColor: "#0090D1",
              borderRadius: "12px",
              padding: "8px 24px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#0090D1",
              },
            }}
          >
            Сохранить
            {editVoucherLoading && (
              <CircularProgress size={20} sx={defaultCircularProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* DEACTIVATE VOUCHER */}
      <Dialog
        open={isVoucherDeactiveModalOpen}
        onClose={handleVoucherDeactivateModalClose}
        // disableScrollLock={true}
        fullWidth={true}
        maxWidth="xs"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              px: 0,
              fontFamily: "Raleway, sans-serif",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Деактивация ваучера
          </DialogTitle>
          <img
            onClick={handleVoucherDeactivateModalClose}
            src={closeIcon}
            className={styles.modalCloseIcon}
            alt="Закрыть"
          />
        </Box>
        <DialogContent sx={{ px: 0 }}>
          <Typography className={styles.editVoucherText}>
            Вы уверены, что хотите деактивировать ваучер? Это приведет к
            заморозке промокодов, относящихся к ваучеру <b>{voucherName}</b>
          </Typography>
          <Divider sx={{ width: "100%" }} />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
          <Button
            type="button"
            variant="contained"
            onClick={(e) => handleIsActivationVoucher(e, false)}
            disabled={editVoucherLoading}
            sx={{
              backgroundColor: "#0090D1",
              borderRadius: "12px",
              padding: "8px 10px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#0090D1",
              },
            }}
          >
            Да, деактивировать
            {editVoucherLoading && (
              <CircularProgress size={20} sx={defaultCircularProgress} />
            )}
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={handleVoucherDeactivateModalClose}
            sx={{
              textTransform: "none",
              color: "#0090D1",
            }}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>

      {/* ACTIVATE VOUCHER */}
      <Dialog
        open={isVoucherActivateModalOpen}
        disableScrollLock={true}
        onClose={handleVoucherActivateModalClose}
        fullWidth={true}
        maxWidth="xs"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle sx={ModalTitle}>Активация ваучера</DialogTitle>
          <img
            onClick={handleVoucherActivateModalClose}
            src={closeIcon}
            className={styles.modalCloseIcon}
            alt="Закрыть"
          />
        </Box>
        <DialogContent sx={{ px: 0 }}>
          <Typography className={styles.editVoucherText}>
            Вы уверены, что хотите активировать ваучер? Это приведет к
            размарозке промокодов, относящихся к ваучеру <b>{voucherName}</b>
          </Typography>
        </DialogContent>
        <Divider sx={{ width: "100%" }} />
        <DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
          <Button
            type="button"
            onClick={(e) => handleIsActivationVoucher(e, true)}
            variant="contained"
            disabled={editVoucherLoading}
            sx={{
              backgroundColor: "#0090D1",
              borderRadius: "12px",
              padding: "8px 24px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#0090D1",
              },
            }}
          >
            Да, активировать
            {editVoucherLoading && (
              <CircularProgress size={20} sx={defaultCircularProgress} />
            )}
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={handleVoucherActivateModalClose}
            sx={{
              textTransform: "none",
              color: "#0090D1",
            }}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>

      {/* DELETE LEFT-OVER PROMOCODES */}
      <Dialog
        open={isVoucherDeleteNonActivatedModalOpen}
        disableScrollLock={true}
        onClose={handleVoucherDeleteNonActivatedModalClose}
        fullWidth={true}
        maxWidth="xs"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle sx={ModalTitle}>Удаление остатков ваучеров</DialogTitle>
          <img
            onClick={handleVoucherDeleteNonActivatedModalClose}
            src={closeIcon}
            className={styles.modalCloseIcon}
            alt="Закрыть"
          />
        </Box>
        <DialogContent sx={{ px: 0 }}>
          <Typography className={styles.editVoucherText}>
            Вы уверены, что хотите удалить оставшиеся промокоды ? Все
            неиспользованные промокоды <b>{voucherName}</b> будут удалены
            навсегда.
          </Typography>
        </DialogContent>
        <Divider sx={{ width: "100%" }} />

        <DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
          <Button
            type="button"
            onClick={handleDeleteNonActivatedVoucher}
            variant="contained"
            disabled={deleteNonActivatedPromocodesLoading}
            sx={{
              backgroundColor: "#0090D1",
              borderRadius: "12px",
              padding: "8px 24px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#0090D1",
              },
            }}
          >
            Да, удалить
            {deleteNonActivatedPromocodesLoading && (
              <CircularProgress size={20} sx={defaultCircularProgress} />
            )}
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={handleVoucherDeleteNonActivatedModalClose}
            sx={{
              textTransform: "none",
              color: "#0090D1",
            }}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
      {/* DELETE SELECTED PROMOCODES MODAL */}
      <Dialog
        open={isDeleteModalOpen.isOpen}
        onClose={() => setIsDeleteModalOpen({ isOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          fontFamily: "Raleway",
          ".MuiDialog-paper": {
            width: "426px",
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle id="modal-modal-title" sx={ModalTitle}>
            Удалить промокоды?
          </DialogTitle>
          <IconButton onClick={() => setIsDeleteModalOpen({ isOpen: false })}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <DialogActions
          sx={{
            justifyContent: "flex-start",
            paddingInline: 0,
            marginTop: "16px",
          }}
        >
          <ButtonWithProgress
            variant="contained"
            onClick={() => {
              deletePromocodes();
              setIsDeleteModalOpen({ isOpen: false });
            }}
            autoFocus
            loading={false}
            sx={{ borderRadius: "16px" }}
          >
            {t("modalDeleteFile.btnDelete") as string}
          </ButtonWithProgress>
          <Button onClick={() => setIsDeleteModalOpen({ isOpen: false })}>
            {t("modalDeleteFile.btnCancel") as string}
          </Button>
        </DialogActions>
      </Dialog>
      <PromoModal
        open={openModal}
        setPromocodeEmail={setPromocodeEmail}
        onClose={() => setOpenModal(false)}
        promocode={promocode}
        handleUpdateState={handleUpdateState}
        setSuccessPromode={setSuccessPromode}
      />
      <SuccessAsignPromode
        promocodeEmail={promodeEmail}
        promocode={promocode}
        open={successPromode}
        handleUpdateState={handleUpdateState}
        onClose={() => setSuccessPromode(false)}
      />
      <UnLinkPromoModal
        handleUpdateState={handleUpdateState}
        open={unPromocodeOpen}
        onClose={() => setUnPromocodeOpen(false)}
        promocode={promocode}
      />
      {voucherPromocodes?.length === 0 ? null : (
        <Stack
          spacing={2}
          sx={{
            margin: "30px auto 20px auto",
            alignItems: "center",
          }}
          className={styles.tablePagination}
        >
          <Pagination
            count={
              voucherPromocodesAmount
                ? Math.ceil(voucherPromocodesAmount / pageSize)
                : 5
            }
            page={page}
            onChange={handleOnPageChange}
            color="primary"
            size="medium"
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      )}
    </div>
  );
};

export default VoucherListTable;
