import * as yup from "yup";

export const addSlotsValidation = yup.object().shape({
  fields: yup.array().of(
    yup.object().shape({
      value: yup.string().required("Выберите дистанцию"),
      slots: yup
        .number()
        .typeError("Укажите количество слотов")
        .positive("Должно быть положительным")
        .integer("Должно быть целым числом")
        .required("Введите количество слотов"),
    })
  ),
});
