import {createAsyncThunk} from "@reduxjs/toolkit";
import {personalCabinetAPI} from "../api";

export const getVouchersCreateThunk = createAsyncThunk(
  'personalCabinet/getVouchersCreateThunk',
  async (payload,{rejectWithValue}) => {
    try {
      const { data } = await personalCabinetAPI.getVouchers(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)
