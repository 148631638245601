import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const putFileCreateThunk = createAsyncThunk(
  "personalCabinet/uploadFile",
  async (fileData, { rejectWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.putFiles(fileData);
      return data;
    } catch (error) {
      console.error(
        "Upload failed, error response:",
        error.response ? error.response.data : error
      );
      return rejectWithValue(
        error.response ? error.response.data : "Unknown error"
      );
    }
  }
);
