import { Box, Button, Typography } from "@mui/material";
import { buttonFileStyle } from "shared/lib/style";
import style from "./load-file.module.scss";
import fileIcon from "assets/images/myDocument/file.svg";
import btnIcon from "assets/images/myDocument/btn_icon.svg";
import trashRedImg from "assets/images/admin/trash_red.svg";
import { UseFormSetValue, useFormContext } from "react-hook-form";
import { ICreateMarathon } from "features/createMarathon/types";
import { useEffect, useState } from "react";
import { register } from "module";
import { useTranslation } from "react-i18next";

interface ILoadDocumentProps {
  setValue: UseFormSetValue<ICreateMarathon>;
  data: ICreateMarathon;
  languageId: number;
  error?: string;
  indexLanguage: number;
}

export const LoadImage: React.FC<ILoadDocumentProps> = ({
  setValue,
  data,
  languageId,
  error,
  indexLanguage,
}) => {
  const [isDragOpen, setIsDragOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState<string>("");
  const { t } = useTranslation();

  const { formState, resetField, register } = useFormContext<ICreateMarathon>();
  const errors = formState.errors;
  const handleLogo = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    // @ts-ignore
    setValue(`translations.${languageId}.logo`, file);
    console.log("drop");
    setIsDragOpen(false);
  };

  if (data.translations[languageId]?.logo?.length) {
    if (data.translations[languageId]?.logo[0].name) {
      const fileReader = new FileReader();
      const fileExtension = data.translations[languageId].logo[0]?.name
        ?.split(".")
        .slice(-1)
        .join("");
      // @ts-ignore
      fileReader.readAsDataURL(data.translations[languageId].logo[0]);

      fileReader.onloadend = () => {
        if (fileExtension === "svg") {
          setImgUrl(
            // @ts-ignore
            fileReader.result?.replace(
              "application/octet-stream",
              "image/svg+xml"
            )
          );
        } else {
          // @ts-ignore
          setImgUrl(fileReader.result);
        }
      };
    }
  }

  const isErrorLogo = errors?.translations?.[indexLanguage]?.logo;

  return (
    <Box>
      <label className={`label-personalCabinet ${style.label}`} htmlFor="-">
      {t("createMarathon.img")}
        {data.translations[languageId]?.logo?.length ? (
          <div
            className={style.box_logo}
            style={{ border: isErrorLogo ? "1px solid red" : "" }}
          >
            <img
              className={style.box_logo_item}
              src={imgUrl}
              alt= {t("createMarathon.marathonLogo")}
            />
            <div className={style.delete_icon}>
              <img
                src={trashRedImg}
                alt= {t("createMarathon.deteFile")}
                onClick={() => {
                  setImgUrl("");
                  resetField(`translations.${languageId}.logo`);
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {!data.translations[languageId]?.logo?.length ? (
          <div
            className={`${style.box_load} ${isDragOpen ? style.box_load_drag : ""
              }
            ${errors.translations?.[languageId]?.logo?.message
                ? style.box_error
                : ""
              }
            `}
            onDragStart={(e) => {
              e.preventDefault();
              setIsDragOpen(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setIsDragOpen(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragOpen(true);
            }}
            onDrop={handleLogo}
          >
            <img src={fileIcon} alt="Файл" />
            <div className={style.box_load_text}>
              {/* <p className={"errorTextValidation"}>{arrTextError[index]}</p> */}
              {isDragOpen ? (
                <p>{t("createMarathon.dropToLoad")}</p>
              ) : (
                <>
                  <p>
                    {t("createMarathon.fileDrag1")} <br /> {t("createMarathon.fileDrag2")}
                  </p>
                  <Button
                    variant="contained"
                    component="label"
                    sx={buttonFileStyle}
                    className={style.box_load_btn}
                  >
                    <img src={btnIcon} alt="Выбрать файл" />
                    <span>{t("createMarathon.chooseFile")}</span>
                    <input
                      type="file"
                      // @ts-ignore
                      {...register(`translations.${languageId}.logo`)}
                      accept="image/png, image/gif, image/jpeg"
                      hidden
                    />
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </label>
      <Box sx={{ width: "100%" }}>
        <Box className="globalErrorMessage">{error}</Box>
      </Box>
    </Box>
  );
};
