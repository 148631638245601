import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const getUserById = createAsyncThunk(
  "admin/getUserById",
  async (id: any, { rejectWithValue }) => {
    try {
      const { data } = await adminAPI.getUserById(id);
      return data;
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);
