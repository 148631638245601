import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";

export const getVoucherDistancesCreateThunk = createAsyncThunk(
  'personalCabinet/getVoucherDistancesCreateThunk',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.getVoucherDistances({ id })
      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
