import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'shared/lib/hooks/hooks';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AdminNavItems from "entities/admin/ui/AdminNavItems/AdminNavItems";
import SwipeDrawer from 'shared/ui/SwipeableDrawer/SwipeDrawer';
import DrawerNavItems from 'entities/user/ui/DrawerNavItems/DrawerNavItems';
import Anonymous from 'entities/user/ui/Anonymous/Anonymous';
import AdminDropDownMenu from "../../shared/ui/AdminDropDownMenu/AdminDropDownMenu";
import AdminDrawerDropDownMenu from "../../entities/admin/ui/AdminDrawerDropDownMenu/AdminDrawerDropDownMenu";
import marathonLogo from 'assets/images/header/marathonLogo.svg';
import styles from './AdminHeader.module.scss';
import { getProfileCreateThunk } from "../../entities/viewer/model/thunks/getProfileCreateThunk";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1101,
      md: 1000,
      lg: 1200,
      xl: 1500,
    },
  },
});

interface AdminHeaderProp {
  window?: () => Window;
}

const AdminHeader: React.FC<AdminHeaderProp> = (props): JSX.Element => {
  const { window } = props;
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(state => state.users.isAuth);
  const [nestedMenuOpen, setNestedMenuOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const token = localStorage.getItem('AccessToken');

  const nestedMenuHandleClick = () => {
    setNestedMenuOpen(!nestedMenuOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    dispatch(getProfileCreateThunk());
  }, [dispatch]);

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      {token && isAuth ? (
        <AdminDrawerDropDownMenu
          nestedMenuHandleClick={nestedMenuHandleClick}
          nestedMenuOpen={nestedMenuOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      ) : (
        <Anonymous handleDrawerToggle={handleDrawerToggle} />
      )}
      <Divider />
      <DrawerNavItems handleDrawerToggle={handleDrawerToggle} />
    </Box>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={styles.headerContainer}>
      <div className="container">
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            <AppBar component="nav" sx={{ backgroundColor: '#fff', boxShadow: 'none', position: 'static' }}>
              <Toolbar style={{ padding: '0' }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, display: { sm: 'block' } }}>
                  <a href='https://runthesilkroad.com/' target="_blank" rel="noreferrer" className={styles.headerLogo}>
                    <img src={marathonLogo} alt="Логотип Run the Silk Road" />
                    <span className={styles.marathonHeader}>Admin Panel</span>
                  </a>
                </Typography>
                <AdminNavItems />
                <AdminDropDownMenu />
                {mobileOpen ? null : (
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ ml: '5px', display: { sm: 'none' } }}
                  >
                    <MenuIcon sx={{ fontSize: 35 }} />
                  </IconButton>
                )}
              </Toolbar>
            </AppBar>
            <SwipeDrawer
              container={container}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              drawer={drawer}
            />
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default AdminHeader;
