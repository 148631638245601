import {
  AdminSettings,
  HeaderArrowDown,
  HeaderArrowUp,
  LanguageInterface,
  LogoutImg,
} from "assets/icons";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "shared/lib/hooks/hooks";
import {
  userDropDownMenuItem,
  userDropDownMenuPaperProps,
  userDropDownMenuTransformOrigin,
} from "widget/MUIStyles";

import Grid from "@mui/material/Grid";
import LanguageDropDown from "../LanguageDropDown/LanguageDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PopoverOrigin } from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { USER_LOGIN_PATH } from "pages/paths";
import { clearUserData } from "entities/viewer/model/slice";
import profileCircle from "assets/images/admin/profile-circle.svg";
import styles from "./AdminDropDownMenu.module.scss";
import { useTranslation } from "react-i18next";
import { userLogout } from "entities/user/model/slice";

const userDropDownMenu = [
  {
    label: "Настройки",
    path: "settings/mockPath1",
    dropDownImage: <AdminSettings />,
  },
  {
    label: "Язык интерфейса",
    path: "settings/mockPath2",
    dropDownImage: <LanguageInterface />,
  },
  { label: "Выйти", path: "/user/login", dropDownImage: <LogoutImg /> },
];

const AdminDropDownMenu: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userName = localStorage.getItem("userName");
  // @ts-ignore
  const name = useAppSelector((state) => state.personalCabinet.profile?.name);
  const profileData = useAppSelector(
    (state: any) => state.personalCabinet?.profile
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("RefreshToken");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("userName");
    localStorage.removeItem("create_marathon_step_2");
    localStorage.removeItem("startDateAcceptingApplications");
    localStorage.removeItem("endDateAcceptingApplications");
    dispatch(userLogout());
    dispatch(clearUserData());
    navigate(USER_LOGIN_PATH);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 1100px)": { display: "none" },
      }}
    >
      <div className={styles.adminProfile} onClick={handleClick}>
        <img src={profileCircle} alt="Профиль" />
      </div>
      <Typography
        className={styles.userName}
        sx={{
          mx: "15px",
          maxWidth: "250px",
          "&:first-of-type": {
            marginRight: "10px",
          },
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {(profileData?.email && (
          <Tooltip title={profileData?.email || "email"}>
            <span>
              {profileData?.email && profileData?.email.length > 29
                ? profileData?.email.slice(0, 29) + "…"
                : profileData?.email || "email"}
            </span>
          </Tooltip>
        )) ||
          (name && (
            <Tooltip title={name || "username"}>
              <span>{name?.length > 29 ? name?.slice(0, 30) + "…" : name}</span>
            </Tooltip>
          )) ||
          (userName && (
            <Tooltip title={userName || "username"}>
              <span>
                {userName && userName.length > 29
                  ? userName?.slice(0, 30) + "…"
                  : userName}
              </span>
            </Tooltip>
          )) ||
          t("userDropDownMenu.guest")}
      </Typography>
      <Menu
        style={{ borderRadius: "10px" }}
        id="basic-menu"
        sx={{ mt: "25px", ml: "130px" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={userDropDownMenuTransformOrigin as PopoverOrigin}
        PaperProps={userDropDownMenuPaperProps}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {userDropDownMenu.map((item, index) => (
          <div key={index}>
            {item.label !== "Язык интерфейса" ? (
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? styles.userDropDownMenuLinkActive
                    : styles.userDropDownMenuLink
                }
              >
                <MenuItem
                  onClick={(e) => {
                    (e.target as HTMLElement).innerText === "Выйти" &&
                      clearLocalStorage();
                    (e.target as HTMLElement).innerText === "Настройки" &&
                      handleClose();
                    (e.target as HTMLElement).innerText === "Выйти" &&
                      handleClose();
                  }}
                  sx={userDropDownMenuItem}
                >
                  {item.dropDownImage}
                  <span className={styles.userDropDownMenuLabel}>
                    {item.label}
                  </span>
                </MenuItem>
              </NavLink>
            ) : (
              <MenuItem
                onClick={() => setIsSelectOpen((prevState) => !prevState)}
                sx={userDropDownMenuItem}
                className={styles.userDropDownMenuLink}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {item.dropDownImage}
                  <span className={styles.userDropDownMenuLabel}>
                    {item.label}
                  </span>
                  <LanguageDropDown isSelectOpen={isSelectOpen} />
                </div>
              </MenuItem>
            )}
          </div>
        ))}
      </Menu>
      <Grid sx={{ position: "relative", top: "2px" }} onClick={handleClick}>
        {open ? <HeaderArrowUp /> : <HeaderArrowDown />}
      </Grid>
    </Grid>
  );
};

export default AdminDropDownMenu;
