import { createSlice } from "@reduxjs/toolkit";
import history from 'shared/lib/history';
import { USER_LOGIN_PATH } from 'pages/paths';
import {
  changePassword, checkUserAuth, forgotPassword,
  registerUser, userConfirmEmail, userLogin, userGoogleLogin, getActiveMarathons, getActiveMarathonById,
} from 'entities/user/model/thunks';
import { SliceState } from "entities/user/lib/types";

const initialState: SliceState = {
  //isAuth
  isAuth: false,

  //googleLogin
  userName: null,

  //userRole
  userRole: null,

  //userId
  userId: null,

  //registerUser
  isRegisterUserSuccess: false,
  registerUserLoading: false,
  registerUserError: null,

  //confirmEmail
  isConfirmEmailSuccess: false,
  confirmEmailLoading: false,
  confirmEmailError: null,
  userSendConfirmEmail: null,

  //loginUser
  loginUserLoading: false,
  isUserLoginSuccess: false,
  loginPasswordError: null,
  loginEmailError: null,

  //forgotPassword
  forgotPasswordLoading: false,
  isForgotPasswordSuccess: false,
  forgotPasswordError: null,

  //changePassword
  changePasswordLoading: false,
  isChangePasswordSuccess: false,

  //checkUserAuth
  isCheckUserLoading: false,

  //listOfActiveMarathons
  activeMarathons: [],
  isGetActiveMarathonsLoading: false,
  activeMarathonById: [],
  isGetActiveMarathonByIdLoading: false,
  activeMarathonParticipantsCount: null,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userLogout: state => {
      state.isAuth = false;
      state.userRole = null;
      localStorage.removeItem('AccessToken');
      localStorage.removeItem('RefreshToken');
      localStorage.removeItem('role');
      localStorage.removeItem('email');
      localStorage.removeItem('userName');
      localStorage.removeItem('create_marathon_step_2');
    },
    cancelEmailConfirmation: state => {
      state.isRegisterUserSuccess = false;
    },
    clearTextFieldErrors: state => {
      state.registerUserError = null;
      state.loginPasswordError = null;
      state.loginEmailError = null;
      state.forgotPasswordError = null;
    },
    setUserSendConfirmEmail: (state, action) => {
      state.userSendConfirmEmail = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        state.loginUserLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        localStorage.setItem('AccessToken', action.payload.accessToken);
        localStorage.setItem('RefreshToken', action.payload.refreshToken);
        localStorage.setItem('role', action.payload.role);
        localStorage.setItem('userId', action.payload.userId);
        state.userRole = action.payload.role;
        state.userId = action.payload.userId;
        state.isUserLoginSuccess = true;
        state.isAuth = true;
        state.loginUserLoading = false;
      })
      .addCase(userLogin.rejected, (state, action: any) => {
        state.isUserLoginSuccess = false;
        state.loginUserLoading = false;
        //email already confirmed 11
        //email not confirmed 13
        //user already registered 16
        //user does not exist 17
        //incorrect password 18
        state.loginEmailError = (
          action.payload.code === 16 ||
          action.payload.code === 17 ||
          action.payload.code === 13
        ) ? action.payload.title : null;

        state.loginPasswordError = action.payload.code === 18 ? action.payload.title : null
      })
      .addCase(checkUserAuth.pending, (state) => {
        state.isCheckUserLoading = true;
      })
      .addCase(checkUserAuth.fulfilled, (state, action) => {
        localStorage.setItem('AccessToken', action.payload.accessToken);
        localStorage.setItem('RefreshToken', action.payload.refreshToken);
        localStorage.setItem('role', action.payload.role);
        localStorage.setItem('userId', action.payload.userId);
        state.userId = action.payload.userId;
        state.userRole = action.payload.role;
        state.isAuth = true;
        state.isCheckUserLoading = false;
      })
      .addCase(checkUserAuth.rejected, state => {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('RefreshToken');
        localStorage.removeItem('role');
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('create_marathon_step_2');
        localStorage.removeItem('startDateAcceptingApplications');
        localStorage.removeItem('endDateAcceptingApplications');
        state.isAuth = false;
        state.isCheckUserLoading = false;
        history.push(USER_LOGIN_PATH);
      })
      .addCase(registerUser.pending, state => {
        state.registerUserLoading = true;
      })
      .addCase(registerUser.fulfilled, state => {
        state.registerUserLoading = false;
        state.isRegisterUserSuccess = true;
      })
      .addCase(registerUser.rejected, (state, action: any) => {
        state.isRegisterUserSuccess = false;
        state.registerUserLoading = false;
        state.registerUserError = action.payload;
      })
      .addCase(userConfirmEmail.pending, state => {
        state.confirmEmailLoading = true;
        state.confirmEmailError = null;
      })
      .addCase(userConfirmEmail.fulfilled, state => {
        state.confirmEmailLoading = false;
        state.isConfirmEmailSuccess = true;
        state.confirmEmailError = null;
      })
      .addCase(userConfirmEmail.rejected, (state, action: any) => {
        state.confirmEmailLoading = false;
        state.confirmEmailError = action.payload;
      })
      .addCase(forgotPassword.pending, state => {
        state.forgotPasswordLoading = true;
      })
      .addCase(forgotPassword.fulfilled, state => {
        state.forgotPasswordLoading = false;
        state.isForgotPasswordSuccess = true;
      })
      .addCase(forgotPassword.rejected, (state, action: any) => {
        state.forgotPasswordError = action.payload;
        state.forgotPasswordLoading = false;
      })
      .addCase(changePassword.pending, state => {
        state.changePasswordLoading = true;
      })
      .addCase(changePassword.fulfilled, state => {
        state.changePasswordLoading = false;
        state.isChangePasswordSuccess = true;
      })
      .addCase(changePassword.rejected, state => {
        state.changePasswordLoading = false;
      })
      .addCase(userGoogleLogin.fulfilled, (state, action) => {
        localStorage.setItem('AccessToken', action.payload.accessToken);
        localStorage.setItem('RefreshToken', action.payload.refreshToken);
        localStorage.setItem('role', action.payload.role);
        localStorage.setItem('userName', action.payload.name);
        state.userRole = action.payload.role;
        state.isAuth = true;
        state.userName = action.payload.name;
      })
      .addCase(getActiveMarathons.pending, state => {
        state.isGetActiveMarathonsLoading = true;
      })
      .addCase(getActiveMarathons.fulfilled, (state, action) => {
        state.isGetActiveMarathonsLoading = false;
        state.activeMarathons = action.payload.query;
      })
      .addCase(getActiveMarathons.rejected, state => {
        state.isGetActiveMarathonsLoading = false;
      })
      .addCase(getActiveMarathonById.pending, state => {
        state.isGetActiveMarathonByIdLoading = true;
      })
      .addCase(getActiveMarathonById.fulfilled, (state, action) => {
        state.isGetActiveMarathonByIdLoading = false;
        state.activeMarathonById = action.payload.query;
        state.activeMarathonParticipantsCount = action.payload.count;
      })
      .addCase(getActiveMarathonById.rejected, state => {
        state.isGetActiveMarathonsLoading = false;
      })
  },
});

export const { userLogout, setUserSendConfirmEmail, cancelEmailConfirmation, clearTextFieldErrors } = userSlice.actions;

export default userSlice.reducer;
