import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";
import { toast } from "react-toastify";

export const getUserInsurance = createAsyncThunk(
  "user/getUserInsurance",
  async (marathonId: number, { rejectWithValue }) => {
    try {
      const response = await adminAPI.getUserInsurance(marathonId);
      return response.data;
    } catch (error: any) {
      console.log(error, "error");
      alert("ghvchgvhgvhgvhg");
      if (error.code == 6) {
        toast.error("Нет возрастной категории для вашего возраста и пола.");
        toast.error("Ой, что-то пошло не так, попробуйте обновить страницу", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Произошла ошибка, попробуйте снова.");
      }
      return rejectWithValue(error);
    }
  }
);
