import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSubVouchersCreateThunk } from "entities/viewer/model/thunks/getSubVouchersCreateThunk";

import {

  Box,
} from "@mui/material";

import { ADMIN_PANEL_PATH, VOUCHERS_PROMOCODES_PATH } from "pages/paths";
import SubVoucher from "entities/viewer/ui/personalCabinet/SubVoucher/SubVoucher";
import { useAppSelector } from "shared/lib/hooks/hooks";

const SubVoucherAdmin = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { voucherId, marathonName, voucherName, corporateClientName } =
    useParams();

  const [pagination, setPagionation] = useState({
    page: 1,
    pageSize: 10,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [currentVoucher, setCurrentVoucher] = useState();

  const handleMenuClick = (event, voucher) => {
    setAnchorEl(event.currentTarget);
    setCurrentVoucher(voucher);
  };

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (voucherId) {
      dispatch(
        getSubVouchersCreateThunk({
          voucherId,
          corporateClientId: null,
          pagination,
        })
      );
    }
  }, [voucherId, pagination]);

  const LinkToPromocodesTable = (subVoucherId) => {
    return (
      "/" +
      ADMIN_PANEL_PATH +
      "/" +
      VOUCHERS_PROMOCODES_PATH +
      "/" +
      subVoucherId
    );
  };

  const handleOnPageChange = (event, page) =>
    setPagionation({ ...pagination, page });

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ maxWidth: "1300px", width: "100%", mt: "40px" }}>
        <SubVoucher voucherId={voucherId} isAdmin={true} marathonName={marathonName} voucherName={voucherName} corporateClientName={corporateClientName} />
      </Box>
    </div>
  );
};

export default SubVoucherAdmin;
