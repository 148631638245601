import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";
import style from "../load-file.module.scss";
import { ICreateMarathon } from "features/createMarathon/types";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFormRegister,
  useFormContext,
} from "react-hook-form";
import { buttonFileStyle } from "shared/lib/style";
import fileIcon from "assets/images/myDocument/file.svg";
import btnIcon from "assets/images/myDocument/btn_icon.svg";
import fileImg from "assets/images/admin/file.svg";
import trashRedImg from "assets/images/admin/trash_red.svg";
import { useParthnerCompany } from "features/createMarathon/model/MarathonTabs/usePartnerCompany";
import { MarathonPartnerLogo } from "./ParthnerImageList";
import { useTranslation } from "react-i18next";

interface ParthnerProps {
  data: ICreateMarathon;
  remove: (index: number) => void;
  append: UseFieldArrayAppend<ICreateMarathon, "partners">;
  register: UseFormRegister<ICreateMarathon>;
  indexLanguage: number;
  partner: FieldArrayWithId<ICreateMarathon, "partners", "id">;
  index: number;
  control: Control<ICreateMarathon, any>;
}

export const Parthner: React.FC<ParthnerProps> = ({
  append,
  data,
  indexLanguage,
  partner,
  register,
  remove,
  index,
  control,
}) => {
  const [isDragOpen, setIsDragOpen] = useState(false);
  const { t } = useTranslation();

  const {
    append: appendParthnerCompany,
    fields,
    remove: removeParthnerCompany,
  } = useParthnerCompany({
    control,
    index,
  });

  const {
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  const handleDowloadImage = (e: any) => {
    if (e?.target?.files)
      // @ts-ignore
      appendParthnerCompany({
        // @ts-ignore
        logo: e?.target?.files,
        name: "",
        url: "",
      });
  };
  const isImageError = errors.partners?.[index]?.partnerCompanies?.message;

  return (
    <Box key={partner.id}>
      <UiTextField
        inputProps={{
          ...register(`partners.${index}.translations.${indexLanguage}.name`),
        }}
        placeholder={t("createMarathon.headerPlaceholder")}
        label={t("createMarathon.header")}
        error={
          errors.partners?.[index]?.translations?.[indexLanguage]?.name?.message
        }
      />
      {!fields.length && (
        <Box>
          <label className={`label-personalCabinet ${style.label}`} htmlFor="-">
          {t("createMarathon.logos")}
            <div
              style={{ border: !!isImageError ? "2px dashed red" : "" }}
              className={`${style.box_load} ${
                isDragOpen ? style.box_load_drag : ""
              }`}
              onDragStart={(e) => {
                e.preventDefault();
                console.log("start");
                setIsDragOpen(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                console.log("leave");
                setIsDragOpen(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                console.log("over");
                setIsDragOpen(true);
              }}
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files;
                // @ts-ignore
                appendParthnerCompany({
                  // @ts-ignore
                  logo: file,
                  name: "",
                  url: "",
                });
                setIsDragOpen(false);
              }}
            >
              <img src={fileIcon} alt="Файл" />
              <div className={style.box_load_text}>
                {/* <p className={"errorTextValidation"}>{arrTextError[index]}</p> */}
                {isDragOpen ? (
                  <p>{t("createMarathon.dropToLoad")}</p>
                ) : (
                  <>
                    <p>
                      {t("createMarathon.fileDrag1")} <br /> {t("createMarathon.fileDrag2")}
                    </p>
  
                    <Button
                      variant="contained"
                      component="label"
                      sx={buttonFileStyle}
                      className={style.box_load_btn}
                    >
                      <img src={btnIcon} alt={t("createMarathon.chooseFile")} />
                      <span>{t("createMarathon.chooseFile")}</span>
                      <input
                        type="file"
                        onChange={handleDowloadImage}
                        accept="image/*"
                        hidden
                      />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </label>
          <Box>
            <Box className="globalErrorMessage">{isImageError}</Box>
          </Box>
        </Box>
      )}
      {fields.length ? (
        <>
          <label className={style.label}>{t("createMarathon.logos")}</label>
          <Box className={style.box_img}>
            {fields.map((item2, index2) => (
              <MarathonPartnerLogo
                register={register}
                item={item2}
                removeParthnerCompany={removeParthnerCompany}
                appendParthnerCompany={appendParthnerCompany}
                index={index}
                index2={index2}
                key={index2}
              />
            ))}
          </Box>
        </>
      ) : (
        ""
      )}
      {fields.length ? (
        <label className={style.text_add}>
          {t("createMarathon.addLogo")}
          <input
            type="file"
            accept="image/*"
            onChange={handleDowloadImage}
            multiple
            hidden
          />
        </label>
      ) : (
        ""
      )}
      {data.partners.length > 1 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            my: "16px",
            cursor: "pointer",
          }}
          onClick={() => {
            remove(index);
          }}
        >
          <Divider sx={{ background: "red", flex: 1 }} />
          <Typography className={style.deleteText}>{t("createMarathon.deletePartner")}</Typography>
          <img src={trashRedImg} alt="" />
          <Divider sx={{ background: "red", flex: 1 }} />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
