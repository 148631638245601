import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
} from "@microsoft/signalr";

export const createSignalRConnection = (hubUrl: string): HubConnection => {
  const connection = new HubConnectionBuilder()
    .withUrl(`https://marathon-app.timelysoft.org${hubUrl}`, {
      accessTokenFactory: () => localStorage.getItem("AccessToken") || "",
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  connection
    .start()
    .then(() => console.log(`Connected to ${hubUrl}`))
    .catch((error: any) => console.error("SignalR Connection Error:", error));

  connection.onclose(() => console.log(`Disconnected from ${hubUrl}`));

  return connection;
};