import { Box, Divider, Typography } from "@mui/material";
import { ICreateMarathon } from "features/createMarathon/types";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFormRegister,
} from "react-hook-form";
import { Parthner } from "./Parthner";
import style from "../load-file.module.scss";
import { useTranslation } from "react-i18next";

interface ILoadDocumentProps {
  data: ICreateMarathon;
  remove: (index: number) => void;
  append: UseFieldArrayAppend<ICreateMarathon, "partners">;
  register: UseFormRegister<ICreateMarathon>;
  fields: FieldArrayWithId<ICreateMarathon, "partners", "id">[];
  indexLanguage: number;
  control: Control<ICreateMarathon, any>;
}

export const P1: React.FC<ILoadDocumentProps> = ({
  data,
  remove,
  append,
  fields,
  control,
  register,
  indexLanguage,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "16px",
          pb: "16px",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", lineHeight: "24px", fontFamily: "Raleway" }}
        >
          {t("createMarathon.partners")}
        </Typography>
        <Divider sx={{ flex: "1", background: "#ECF2F7" }} />
      </Box>
      {fields.map((partner, index) => {
        return (
          <Parthner
            key={partner.id}
            {...{
              data,
              control,
              remove,
              append,
              fields,
              register,
              indexLanguage,
              index,
              partner,
            }}
          />
        );
      })}
      <label
        className={style.text_add}
        onClick={() => {
          append({
            id: "random",
            partnerCompanies: [],
            serialNumber: 23,
            translations: [],
          });
        }}
      >
        {t("createMarathon.addPartner")}
      </label>
    </Box>
  );
};
