import { Box, Button, Divider } from "@mui/material";
import { DeleteDistance, Setting } from "assets/icons";
import { periodSettingButtonStyles } from "entities/admin/lib/MUIStyles/MUIStyles";
import { ICreateMarathon } from "features/createMarathon/types";
import { UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { UiCheckBox } from "shared/ui/UiCheckBox/UICheckBox";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";
import { PricePeriodDistance } from "./PricePeriodDistance";
import { useEffect, useState } from "react";
import { usePeriodDistance } from "features/createMarathon/model/Distance/usePeriodDistance";
import { useCreateAges } from "features/createMarathon/model/Distance/useCreateAges";
import { DistanceAge } from "./DistanceAge";
import { DistanceItem } from "./DistanceItem";
import { ErrorMessage } from "@hookform/error-message";

interface CreateDistanceItemProps {
  index: number;
  removeDistance: UseFieldArrayRemove;
}

export const CreateDistanceItem = ({
  index,
  removeDistance,
}: CreateDistanceItemProps) => {
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  const {
    appendPeriodDistance,
    removePeriodDistance,
    fieldsPeriodDistance,
    updatePeriodDistance,
  } = usePeriodDistance(index);

  const { fieldsAges, appendAge, removeAge } = useCreateAges({
    indexDistance: index,
  });

  const [indexD, setIndexD] = useState(0);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const prev = parseInt(
      getValues(`distances.${index - 1}.startNumbersTo`),
      10
    );
    if (index > 0) {
      setValue(`distances.${index}.startNumbersFrom`, `${prev + 1}`);
    }
  }, [getValues, index, setValue]);

  return (
    <Box sx={{ mt: "16px" }}>
      <UiTextField
        error={errors?.distances?.[index]?.name?.message}
        label="Наименование дистанции"
        inputProps={{ ...register(`distances.${index}.name`) }}
      />
      <Box
        sx={{
          mt: "16px",
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          gap: "15px",
        }}
      >
        <UiTextField
          error={errors?.distances?.[index]?.startNumbersFrom?.message}
          label="Диапазон стартовых номеров"
          inputProps={{ ...register(`distances.${index}.startNumbersFrom`) }}
          type="number"
        />
        <UiTextField
          error={errors?.distances?.[index]?.startNumbersTo?.message}
          inputProps={{ ...register(`distances.${index}.startNumbersTo`) }}
          type="number"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontSize: "12px",
        }}
      >
        <Box className="globalErrorMessage">
          <ErrorMessage name="distances" errors={errors} />
        </Box>
      </Box>
      <UiCheckBox
        control={control}
        label={"Возможность приобретения страховки"}
        name={`distances.${index}.hasInsurance`}
      />
      <Box sx={{ display: "flex", flexDirection: "column", mt: "16px" }}>
        <label className="label">Сумма участия</label>
        {fieldsPeriodDistance.length ? (
          ""
        ) : (
          <Button
            startIcon={<Setting />}
            onClick={() => {
              setIndexD(0);
              setIsEdit(false);
              setOpen(true);
            }}
            sx={{
              ...periodSettingButtonStyles,
              width: "294px",
              height: "40px",
              mt: "3px",
            }}
          >
            Настроить стоимость по периодам
          </Button>
        )}
        <Box>
          {fieldsPeriodDistance.map((field, index) => {
            return (
              <DistanceItem
                field={field}
                onClick={() => {
                  setIndexD(index);
                  setIsEdit(true);
                  setOpen(true);
                }}
                key={field.id}
                remove={() => removePeriodDistance(index)}
              />
            );
          })}
          {fieldsPeriodDistance.length &&
          !(
            fieldsPeriodDistance[fieldsPeriodDistance.length - 1].dateEnd ===
            watch().endDateAcceptingApplications
          ) ? (
            <Button
              onClick={() => {
                setIndexD(0);
                setIsEdit(false);
                setOpen(true);
              }}
            >
              добавить период
            </Button>
          ) : (
            ""
          )}
        </Box>
        <PricePeriodDistance
          appendPeriodDistance={appendPeriodDistance}
          indexDistance={index}
          fields={fieldsPeriodDistance}
          open={open}
          isEdit={isEdit}
          indexD={indexD}
          updatePeriodDistance={updatePeriodDistance}
          removePeriodDistance={removePeriodDistance}
          closeHandler={() => {
            setOpen(false);
          }}
          saveAndClose={() => {
            setOpen(false);
          }}
        />
        <Box className="globalErrorMessage">
          <ErrorMessage
            name="pricePeriodDistances"
            errors={errors}
            render={({ message }) => (
              <span className="globalErrorMessage">{message}</span>
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          mt: "32px",
        }}
      >
        <DistanceAge
          fieldsAges={fieldsAges}
          index={index}
          removeAge={removeAge}
          appendAge={appendAge}
          type="male"
        />

        <DistanceAge
          fieldsAges={fieldsAges}
          index={index}
          removeAge={removeAge}
          appendAge={appendAge}
          type="female"
        />
      </Box>
      <Button
        type="button"
        sx={{ textTransform: "none", marginBottom: "15px" }}
        startIcon={<DeleteDistance />}
        color="error"
        onClick={() => removeDistance(index)}
      >
        Удалить дистанцию
      </Button>
      <Divider />
    </Box>
  );
};
