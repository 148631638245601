import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";
import { alertError } from "shared/api/alertError";

interface getUnssignedCountPerDistanceData {
  distanceName: string,
  promocodeCount: number,
}

interface getUnssignedCountPerDistanceParams {
  voucherId: number | string | undefined,
}

export const getUnssignedCountPerDistance = createAsyncThunk<
  getUnssignedCountPerDistanceData[],
  getUnssignedCountPerDistanceParams
>(
  "getUnssignedCountPerDistance",
  async ({ voucherId }, { rejectWithValue }) => {
    if (!voucherId) {
      return rejectWithValue("Voucher ID is required");
    }

    try {
      const res = await personalCabinetAPI.getUnssignedCountPerDistance(voucherId);
      return res.data;
    } catch (error: any) {
      if (error.response?.data?.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response?.data?.title || "Unknown error");
    }
  }
);