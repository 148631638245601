import { Box } from "@mui/material";
import { LoadDocument } from "../LoadFile/loadDocument";
import { LoadImage } from "../LoadFile/loadImage";
import { LoadPublicoffer } from "../LoadFile/loadPublicOffer";
import { P1 } from "../LoadFile/Parthner/Parthners";
import { TextAreaDescription } from "../LoadFile/textAreaDescription";
import { UiDatetField } from "shared/ui/UiDatePicker/UiDateField";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";
import dayjs from "dayjs";
import { useMarathonTabForm } from "features/createMarathon/model/MarathonTabs/useMarathonTabForm";
import { useTranslation } from "react-i18next";

export const RuTab = () => {
  const {
    data,
    register,
    fields,
    appendDocument,
    control,
    setValue,
    removeDocument,
    resetField,
    updatePublickOffer,
    insertPublicOffer,
    appendPublicOffer,
    removePublicOffer,
    appendPartners,
    removePartners,
    fieldsPartners,
    clearErrors,
    replacePublicOffer,
    errors,
  } = useMarathonTabForm();
  const { t } = useTranslation();

  const indexLanguage = 1;
  const languageId = 2;
  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <UiTextField
        placeholder={t("createMarathon.enterName")}
        label={t("createMarathon.name")}
        inputProps={{
          ...register(`translations.${indexLanguage}.name`),
        }}
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.name?.message
            : ""
        }
      />
      <UiDatetField
        minDate={dayjs()}
        control={control}
        placeholder={t("createMarathon.picDate")}
        label={t("createMarathon.date")}
        name="dateEvent"
      />
      <UiTextField
        inputProps={{
          ...register(`translations.${indexLanguage}.place`),
        }}
        placeholder={t("createMarathon.enterPlace")}
        label={t("createMarathon.place")}
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.place?.message
            : ""
        }
      />
      <UiTextField
        inputProps={{
          ...register(`translations.${indexLanguage}.startKitMessage`),
        }}
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.startKitMessage?.message
            : ""
        }
        placeholder={t("createMarathon.enterKitInfo")}
        label={t("createMarathon.kitIncludes")}
      />
      <LoadImage
        indexLanguage={indexLanguage}
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.logo?.message
            : ""
        }
        data={data}
        setValue={setValue}
        languageId={indexLanguage}
      />
      <LoadPublicoffer
        updatePublickOffer={updatePublickOffer}
        replacePublicOffer={replacePublicOffer}
        insertPublicOffer={insertPublicOffer}
        indexLanguage={indexLanguage}
        languageId={languageId}
        appendPublicOffer={appendPublicOffer}
        data={data}
        removePublicOffer={removePublicOffer}
      />
      <TextAreaDescription
        indexLanguage={indexLanguage}
        placeholder={t("createMarathon.enterDesc")}
        handleChange={(text: string) => {
          setValue(`translations.${indexLanguage}.text`, text);
          clearErrors(`translations.${indexLanguage}.text`);
        }}
        value={data.translations[indexLanguage]?.text}
      />
      <LoadDocument
        append={appendDocument}
        data={data}
        removeDocument={removeDocument}
      />
      <P1
        control={control}
        indexLanguage={indexLanguage}
        append={appendPartners}
        fields={fieldsPartners}
        data={data}
        register={register}
        remove={removePartners}
      />
    </Box>
  );
};
