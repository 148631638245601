import { Box, Button, Divider, Typography } from "@mui/material";
import { buttonFileStyle } from "shared/lib/style";
import style from "./load-file.module.scss";
import fileIcon from "assets/images/myDocument/file.svg";
import btnIcon from "assets/images/myDocument/btn_icon.svg";
import fileImg from "assets/images/admin/file.svg";
import trashRedImg from "assets/images/admin/trash_red.svg";
import { ICreateMarathon } from "features/createMarathon/types";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  useFormContext,
} from "react-hook-form";
import { useState } from "react";
import { PlusIcon } from "assets/icons";
import { useTranslation } from "react-i18next";

interface ILoadDocumentProps {
  data: ICreateMarathon;
  removeDocument: (index: number) => void;
  append: UseFieldArrayAppend<ICreateMarathon, "documents">;
}

export const LoadDocument: React.FC<ILoadDocumentProps> = ({
  data,
  removeDocument,
  append,
}) => {
  const {
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  const [isDragOpen, setIsDragOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "16px",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", lineHeight: "24px", fontFamily: "Raleway" }}
        >
          {t("createMarathon.documents")}
        </Typography>
        <Divider sx={{ flex: "1", background: "#ECF2F7" }} />
      </Box>
      <label className={`label-personalCabinet ${style.label}`} htmlFor="-">
        {t("createMarathon.file")}
        {data.documents.length ? (
          ""
        ) : (
          <div
            style={{
              border: errors.documents ? "2px dashed red" : "",
            }}
            className={`${style.box_load} ${isDragOpen ? style.box_load_drag : ""
              } `}
            onDragStart={(e) => {
              e.preventDefault();
              setIsDragOpen(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setIsDragOpen(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragOpen(true);
            }}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0];
              // @ts-ignore
              append(file);
              setIsDragOpen(false);
            }}
          >
            <img src={fileIcon} alt="Файл" />
            <div className={style.box_load_text}>
              {/* <p className={"errorTextValidation"}>{arrTextError[index]}</p> */}
              {isDragOpen ? (
                <p>{t("createMarathon.dropToLoad")}</p>
              ) : (
                <>
                  <p>
                    {t("createMarathon.fileDrag1")} <br /> {t("createMarathon.fileDrag2")}
                  </p>
                  <Button
                    variant="contained"
                    component="label"
                    sx={buttonFileStyle}
                    className={style.box_load_btn}
                  >
                    <img src={btnIcon} alt={t("createMarathon.chooseFile")} />
                    <span>{t("createMarathon.chooseFile")}</span>
                    <input
                      type="file"
                      onChange={(e) => {
                        if (e.target.files)
                          // @ts-ignore
                          append(e.target.files[0]);
                      }}
                      accept=".pdf,.docx"
                      hidden
                    />
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </label>
      {data.documents.map((file, index) => {
        return (
          <div className={style.main}>
            <div className={style.main__first}>
              <img className={style.main__image} src={fileImg} alt="Файл" />
              <p className={style.main__fileName}>
                {file?.name}{" "}
                <span>
                  {file?.size < 1048576
                    ? `${(file?.size / 1024).toFixed(1)} KB`
                    : `${(file?.size / 1048576).toFixed(1)} MB`}
                </span>
              </p>
            </div>
            <div>
              <img
                style={{ cursor: "pointer" }}
                src={trashRedImg}
                alt="Удалить файл"
                onClick={() => removeDocument(index)}
              />
            </div>
          </div>
        );
      })}
      <Box>
        <Box className="globalErrorMessage">{errors.documents?.message}</Box>
      </Box>
      {data.documents.length ? (
        <label
          style={{
            paddingTop: "16px",
          }}
          className={style.text_add}
        >
          <PlusIcon /> {t("createMarathon.addDocument")}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files) {
                // @ts-ignore
                const file = e.target.files[0];
                // @ts-ignore
                append(file);
              }
            }}
            multiple
            hidden
          />
        </label>
      ) : (
        ""
      )}
    </Box>
  );
};
