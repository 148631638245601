import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const getDisabledPeople = createAsyncThunk(
  "admin/getDisabledPeople",
  async (query : any) => {
    try {
      const { data } = await adminAPI.getDisabledPeople(query)
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);